import { SIGNIN_REQUEST, SIGNIN_RESPONSE, SET_USER_DATA, UNSET_USER_DATA } from "../actions/types";


const initialState = {
  loading:false,
  signinResponse:null,
  logoutResponse:null,
  token: JSON.parse(localStorage.getItem("sdcijhafiehfewui")) != null ? JSON.parse(localStorage.getItem("sdcijhafiehfewui")).token : null,
  username: JSON.parse(localStorage.getItem("sdcijhafiehfewui")) != null ? JSON.parse(localStorage.getItem("sdcijhafiehfewui")).username : null,
  name: JSON.parse(localStorage.getItem("sdcijhafiehfewui")) != null ? JSON.parse(localStorage.getItem("sdcijhafiehfewui")).name : null,
  email: JSON.parse(localStorage.getItem("sdcijhafiehfewui")) != null ? JSON.parse(localStorage.getItem("sdcijhafiehfewui")).email : null,
  level: JSON.parse(localStorage.getItem("sdcijhafiehfewui")) != null ? JSON.parse(localStorage.getItem("sdcijhafiehfewui")).level : null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_REQUEST:
      localStorage.setItem("sdcijhafiehfewui", null);
      return {
        ...state,
        loading:true,
        logoutResponse:null,
        signinResponse:action.data,
        token: null,
        username: null,
        name: null,
        email: null,
        level: null,
      }
    break;
    case SIGNIN_RESPONSE:
      return {
        ...state,
        loading:false,
        signinResponse:action.data,
      }
    break;
    case SET_USER_DATA:
        localStorage.setItem("sdcijhafiehfewui", JSON.stringify(action.data));
        return {
          ...state,
          loading:false,
          signinResponse:null,
          token:action.data.token,
          username:action.data.username,
          email:action.data.email,
          name:action.data.name,
          level:action.data.level,
        }
      break;  
      case UNSET_USER_DATA:
        localStorage.setItem("sdcijhafiehfewui", null);
        console.log('LOGOUT COK!')
        return {
          ...state,
          loading:false,
          logoutResponse:action.data,
          signinResponse:null,
          token: null,
          username: null,
          name: null,
          email: null,
          level: null,
        }
      break;  
    default:
      return state;
  }
}