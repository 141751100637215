export default function () {
  return [
    {
      id:1,
      title: "Home",
      to: "/home",
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: ""
    },
    {
      id:2,
      title: "Produk",
      htmlBefore: '<i class="material-icons">inventory_2</i>',
      open: false,
      items: [{
        title: 'Tambah Produk',
        to: '/add-product',
      }, {
        title: 'Daftar Produk',
        to: '/manage-product',
      }],
    },
    {
      id:3,
      title: "Pesanan",
      htmlBefore: '<i class="material-icons">receipt_long</i>',
      to: "/orders",
    },
    {
      id:4,
      title: "Chat",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      to: "/chat",
    },
    {
      id:5,
      title: "Reseller",
      htmlBefore: '<i class="material-icons">people_alt</i>',
      open: false,
      items: [{
        title: 'List Reseller',
        to: '/add-product',
      },
      {
        title: 'Transaksi Berlangsung',
        to: '/manage-product',
      },
      {
        title: 'Transaksi Berakhir',
        to: '/manage-product',
      }],
    },
    {
      id:6,
      title: "Akun",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      to: "/account",
    }
  ];
}


