import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import shortid from "shortid";
import { 
  Card, 
  CardBody,
  Row, 
  Button, } from "shards-react";

import Chart from "../../utils/chart";
import Tooltip from '@material-ui/core/Tooltip';

import { connect } from "react-redux";
import { updateIntent, deleteIntent } from "../../redux/actions/IntentAction";
import { addKeyword } from "../../redux/actions/KeywordAction";
import ModalYesOrNo from '../modal/modalYesOrNo';
import ModalYesOrNoOneString from '../modal/modalYesOrNoOneString';
import ModalYesOrNoTwoString from '../modal/modalYesOrNoTwoString';

class SmallStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalYesOrNo: false,
      showModalOneString: false,

      titleModalYesOrNo:null, 
      subtitleModalYesOrNo:null, 
      placeholderModalYesOrNo:null, 
      buttonPositiveModalYesOrNo:null,      

      titleModalOneString:null, 
      subtitleModalOneString:null, 
      placeholderModalOneStringA:null, 

      titleModalTwoString:null, 
      subtitleModalTwoString:null, 
      placeholderModalTwoStringA:null, 
      placeholderModalTwoStringB:null,

      data:null,
      position:null
    }
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartOptions = {
      ...{
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          custom: false
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 0.33
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: false,
              scaleLabel: false,
              ticks: {
                display: false,
                isplay: false,
                // Avoid getting the graph line cut of at the top of the canvas.
                // Chart.js bug link: https://github.com/chartjs/Chart.js/issues/4790
                //suggestedMax: Math.max(...this.props.chartData[0].data) + 1
                suggestedMax: 0
              }
            }
          ]
        }
      },
      ...this.props.chartOptions
    };

    const chartConfig = {
      ...{
        type: "line",
        data: {
          ...{
            labels: this.props.chartLabels
          },
          ...{
            datasets: this.props.chartData
          }
        },
        options: chartOptions
      },
      ...this.props.chartConfig
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  showModaYesOrNoController =(titleModalYesOrNo, subtitleModalYesOrNo, buttonPositiveModalYesOrNo, position, data)=>{
    this.setState({
      titleModalYesOrNo:titleModalYesOrNo,
      subtitleModalYesOrNo:subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo:buttonPositiveModalYesOrNo,
      position:position,
      data:data,
      showModalYesOrNo: true
    })
  
  }
  positiveModalYesOrNo =(position, data)=>{
    const { token, deleteIntent } = this.props
    if(position="DELETE_INTENT"){
      let params = {
        id:data.id
      }
      deleteIntent(token, params)
    }
    this.setState({
      showModalYesOrNo: false
    })
  }
  negativeModalYesOrNo =()=>{
    this.setState({
      showModalYesOrNo: false
    })
  }

  showModaOneStringlController =(titleModalOneString, subtitleModalOneString, placeholderModalOneStringA, data, position)=>{
    this.setState({
      titleModalOneString:titleModalOneString,
      subtitleModalOneString:subtitleModalOneString,
      placeholderModalOneStringA:placeholderModalOneStringA,
      data:data,
      position:position,
      showModalOneString: true
    })

  }
  positiveModalOneString =(position, data)=>{
    const { token, addKeyword } = this.props
    if(position="ADD_KEYWORD"){
      let params = {
        category_intent_id:data.data.category_intent_id,
        intent_id: data.data.id,
        sub_intent_name:data.value
      }
      addKeyword(token, params)
    }
    this.setState({
      showModalOneString: false
    })
  }
  negativeModalOneString =()=>{
    this.setState({
      showModalOneString: false
    })
  }

  showModaTwoStringlController =(titleModalTwoString, subtitleModalTwoString, placeholderModalTwoStringA, placeholderModalTwoStringB, data, position)=>{
    this.setState({
      titleModalTwoString:titleModalTwoString,
      subtitleModalTwoString:subtitleModalTwoString,
      placeholderModalTwoStringA:placeholderModalTwoStringA,
      placeholderModalTwoStringB:placeholderModalTwoStringB,
      data:data,
      position:position,
      showModalTwoString: true
    })

  }
  positiveModalTwoString =(position, data)=>{
    const { token, updateIntent } = this.props
    if(position="UPDATE_INTENT"){
      let params = {
        id:data.data.id,
        intent_name:data.ModalTwoStringA,
        intent_wording_pertama: data.ModalTwoStringB
      }
      updateIntent(token, params)
    }
    this.setState({
      showModalTwoString: false
    })
  }
  negativeModalTwoString =()=>{
    this.setState({
      showModalTwoString: false
    })
  }

  render() {
    const { variation, label, value, percentage, increase, wording, data } = this.props;
    const { 
      showModalYesOrNo,
      titleModalYesOrNo, 
      subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo,

      showModalOneString, 
      titleModalOneString, 
      subtitleModalOneString, 
      placeholderModalOneStringA, 

      showModalTwoString,
      titleModalTwoString, 
      subtitleModalTwoString, 
      placeholderModalTwoStringA, 
      placeholderModalTwoStringB,
      position
    } = this.state
    const cardClasses = classNames(
      "stats-small",
      variation && `stats-small--${variation}`
    );

    const cardBodyClasses = classNames(
      variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
    );

    const innerWrapperClasses = classNames(
      "d-flex",
      variation === "1" ? "flex-column m-auto" : "px-3"
    );

    const dataFieldClasses = classNames(
      "stats-small__data",
      variation === "1" && "text-center"
    );

    const labelClasses = classNames(
      "stats-small__label",
      "text-uppercase",
      "mt-2",
      variation !== "1" && "mb-1"
    );

    const valueClasses = classNames(
      "stats-small__value",
      "count",
      variation === "1" ? "my-3" : "m-0"
    );

    const innerDataFieldClasses = classNames(
      "stats-small__data",
      variation !== "1" && "text-right align-items-center"
    );

    const percentageClasses = classNames(
      "stats-small__percentage",
      `stats-small__percentage--${increase ? "increase" : "decrease"}`
    );

    const canvasHeight = variation === "1" ? 100 : 60;
     // console.log('Math.max(...this.props.chartData[0].data)', this.props)
    return (
      <div  >
      <Card small className={cardClasses} style={{width:350, height:160}} onClick={()=>console.log('Click Card')}>
        <CardBody className={cardBodyClasses} >
          <div className={innerWrapperClasses}>
            <div className={dataFieldClasses}>
              <span className={labelClasses}>{label}</span>
              <h6 className={valueClasses}>{value}</h6>
            </div>
            <div className={innerDataFieldClasses}>
              <span className={percentageClasses}>{percentage}</span>

            </div>
            <div className={dataFieldClasses} >
              <span style={{fontSize:10}}>{wording}</span>
            </div>
              <Row 
                className="d-flex justify-content-between px-2"
                style={{width:350, zIndex:2}}>
                  <Tooltip title="Delete Intent">
                    <Button     
                      onClick={()=> this.showModaYesOrNoController("Delete Intent","Warning: If you are sure to delete this intent ("+label+"), you will to delete all keywords in this intent ("+label+")","Delete","DELETE_INTENT", data)} 
                      theme="white" >
                      <i className="material-icons">delete</i>
                    </Button>
                  </Tooltip>

                  <div>
                    <Tooltip title="Add Keyword">
                      <Button
                        onClick={()=> this.showModaOneStringlController("Add Keyword for Intent "+label,"Please fill new keyword name", "Keyword", data, "ADD_KEYWORD")}                 
                        theme="white" >
                        <i className="material-icons">add</i>
                      </Button> 
                    </Tooltip>
                    <Tooltip title="Edit Intent">
                      <Button     
                      onClick={()=> this.showModaTwoStringlController("Update Intent "+label,"Please fill Intent Name and Wording", "Intent Name", "Wording",data, "UPDATE_INTENT")}            
                        theme="white" >
                        <i className="material-icons">edit</i>
                      </Button>  
                    </Tooltip>
              
                    <Tooltip title="Details Intent">
                      <Button                 
                        theme="white" >
                        <i className="material-icons">visibility</i>
                      </Button>
                    </Tooltip>

                  </div>

              </Row>

          </div>
          <canvas
            height={canvasHeight}
            ref={this.canvasRef}
            className={`stats-small-${shortid()}`}
          />
        </CardBody>
      </Card>
      <ModalYesOrNo 
          titleModalYesOrNo={titleModalYesOrNo}
          subtitleModalYesOrNo={subtitleModalYesOrNo}
          showModal={showModalYesOrNo} 
          position={position}
          data={data}
          positiveModalYesOrNo={this.positiveModalYesOrNo}
          negativeModalYesOrNo={this.negativeModalYesOrNo}
          buttonPositiveModalYesOrNo={buttonPositiveModalYesOrNo} />
        <ModalYesOrNoOneString 
          titleModalOneString={titleModalOneString}
          subtitleModalOneString={subtitleModalOneString}
          placeholderModalOneStringA={placeholderModalOneStringA}
          showModal={showModalOneString} 
          position={position}
          data={data}
          positiveModalOneString={this.positiveModalOneString}
          negativeModalOneString={this.negativeModalOneString} />          
        <ModalYesOrNoTwoString 
          titleModalTwoString={titleModalTwoString}
          subtitleModalTwoString={subtitleModalTwoString}
          placeholderModalTwoStringA={placeholderModalTwoStringA}
          placeholderModalTwoStringB={placeholderModalTwoStringB}
          showModal={showModalTwoString} 
          position={position}
          data={data}
          positiveModalTwoString={this.positiveModalTwoString}
          negativeModalTwoString={this.negativeModalTwoString} />

      </div>

    );
  }
}

SmallStats.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  wording: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The percentage number or string.
   */
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether is a value increase, or not.
   */
  increase: PropTypes.bool,
  /**
   * The Chart.js configuration object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options object.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.array.isRequired,
  /**
   * The chart labels.
   */
  chartLabels: PropTypes.array,
  data: PropTypes.object
};

SmallStats.defaultProps = {
  increase: true,
  percentage: 0,
  value: 0,
  label: "Label",
  chartOptions: Object.create(null),
  chartConfig: Object.create(null),
  chartData: [
    {
      label: "Today",
      fill: "start",
      borderWidth: 1.5,
      backgroundColor: "rgba(0, 184, 216, 0.1)",
      borderColor: "rgb(0, 184, 216)",
      data: [1, 2, 1, 3, 5, 4, 7]
    }
    ],
  chartLabels: [null, null, null, null, null, null, null],
  wording: "Wording pertama kali muncul"
};
const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  withRouter : state.withRouter
});

const mapDispatchToProps = {
  addKeyword,
  updateIntent,
  deleteIntent
};
export default connect(mapStateToProps, mapDispatchToProps)(SmallStats);
