import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import ListProducts from "./views/ListProducts";
import AddProduct from "./views/AddProduct";
import Errors from "./views/Errors";
import SigninScreen from "./views/SigninScreen";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/signin",
    layout: EmptyLayout,
    component: SigninScreen
  },
  {
    path: "/orders",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/manage-product",
    layout: DefaultLayout,
    component: ListProducts
  },
  {
    path: "/add-product",
    layout: DefaultLayout,
    component: AddProduct
  },
  {
    path: "/account",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/chat",
    layout: DefaultLayout,
    component: Errors
  },
];
