import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Colors from "../components/components-overview/Colors";
import FormValidation from "../components/components-overview/FormValidation";
import SigninForm from "../components/SigninComponents/SigninForm";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
class SigninScreen extends React.Component {
    render() {
      return (
        <>
            <Container fluid className="justify-content-center">
                <Row noGutters className="justify-content-center">
                    <Col lg="4" className="mt-5 justify-content-center">
                    <Card small>
                        <CardHeader >
                        <h6 className="m-0">Signin Form </h6>
                        </CardHeader>
                        <ListGroupItem className="p-3">
                            <SigninForm />
                        </ListGroupItem>
                    </Card>
                    </Col>
                </Row>
            </Container>


        </>
      );
    }
  }

export default SigninScreen