export const SIGNIN_REQUEST     = 'SIGNIN_REQUEST';
export const SIGNIN_RESPONSE    = 'SIGNIN_RESPONSE';

export const SET_USER_DATA      = 'SET_USER_DATA';
export const UNSET_USER_DATA    = 'UNSET_USER_DATA';

export const ADD_CATEGORY       = 'ADD_CATEGORY';
export const UPDATE_CATEGORY    = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY    = 'DELETE_CATEGORY';
export const READ_ALL_CATEGORY      = 'READ_ALL_CATEGORY';

export const ADD_INTENT       = 'ADD_INTENT';
export const UPDATE_INTENT    = 'UPDATE_INTENT';
export const DELETE_INTENT    = 'DELETE_INTENT';
export const READ_ALL_INTENT      = 'READ_INTENT';

export const ADD_KEYWORD       = 'ADD_KEYWORD';
export const UPDATE_KEYWORD    = 'UPDATE_KEYWORD';
export const DELETE_KEYWORD    = 'DELETE_KEYWORD';
export const READ_ALL_KEYWORD      = 'READ_KEYWORD';


export const CHANGE= "CHANGE";
export const TOGGLE_SIDEBAR= "TOGGLE_SIDEBAR";
export const TOGGLE_SIDEBAR_DROPDOWN= "TOGGLE_SIDEBAR_DROPDOWN";

export const ADD_PRODUCT       = 'ADD_PRODUCT';
export const UPDATE_PRODUCT    = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT    = 'DELETE_PRODUCT';
export const READ_ALL_PRODUCT      = 'READ_ALL_PRODUCT';