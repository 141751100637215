import { 
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    READ_ALL_PRODUCT
 } from "../actions/types";


const initialState = {
  loading:false,
  addProductResponse:null,
  updateProductResponse:null,
  deleteProductResponse:null,
  readProductResponse:null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        loading:true,
        addProductResponse: action.data
      }
    break;
    case UPDATE_PRODUCT:
        return {
            ...state,
            loading:true,
            updateProductResponse: action.data
          }
    break;
    case DELETE_PRODUCT:
        return {
            ...state,
            loading:true,
            deleteProductResponse: action.data
          }
      break;  
      case READ_ALL_PRODUCT:
        return {
            ...state,
            loading:true,
            readProductResponse: action.data
          }
      break;  
    default:
      return state;
  }
}