import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
    Row,
    Col,
    Form,
    FormInput,
    FormSelect,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "shards-react";

class ModalYesOrNoTwoString extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ModalTwoStringA:null,
        ModalTwoStringB:null
      }
      this.canvasRef = React.createRef();
    }

    static propTypes = {
        showModal: PropTypes.bool,
        positiveModalTwoString: PropTypes.func,
        negativeModalTwoString: PropTypes.func,
        titleModalTwoString: PropTypes.string,
        subtitleModalTwoString: PropTypes.string,
        placeholderModalTwoStringA: PropTypes.string,
        placeholderModalTwoStringB: PropTypes.string,
        position: PropTypes.string,
        data: PropTypes.number
        
    }
    
    static defaultProps = {
        showModal:false,
    }

    getClasses = () =>{
        const useStyles = makeStyles((theme) => ({
            paper: {
              position: 'absolute',
              width: 400,
              backgroundColor: theme.palette.background.paper,
              border: '2px solid #000',
              boxShadow: theme.shadows[5],
              padding: theme.spacing(2, 4, 3),
            },
          }));
          return useStyles
    }
  render(){

    const { 
        showModal, 
        positiveModalTwoString, 
        negativeModalTwoString,
        position,
        data,
        titleModalTwoString, 
        subtitleModalTwoString,
        placeholderModalTwoStringA,
        placeholderModalTwoStringB,
     } = this.props
     const { ModalTwoStringA, ModalTwoStringB } =this.state
    return (
        <div >
            <Modal
                open={showModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                    <div style={{width:400,alignSelf:'center'}} className={[this.getClasses()," modal-dialog modal-dialog-centered"]}>
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">{titleModalTwoString}</h5>
                        </div>
                        <div class="modal-body">
                            <Row>
                                <h6 style={{textAlign:'center'}} >{subtitleModalTwoString}</h6>
                                <Form style={{width:'100%'}}>
                                    <FormGroup>
                                        <FormInput
                                            placeholder={placeholderModalTwoStringA}
                                            require
                                            value={ModalTwoStringA}
                                            onChange={(text) => this.setState({ModalTwoStringA:text.target.value})}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormInput
                                            placeholder={placeholderModalTwoStringB}
                                            require
                                            value={ModalTwoStringB}
                                            onChange={(text) => this.setState({ModalTwoStringB:text.target.value})}
                                        />
                                    </FormGroup>
                                </Form>
                            </Row>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={()=>negativeModalTwoString()}>Close</button>
                            <button type="button" class="btn btn-primary" onClick={()=>positiveModalTwoString(position,{data:data, ModalTwoStringA:ModalTwoStringA, ModalTwoStringB:ModalTwoStringB})}>Save changes</button>
                        </div>
                        </div>
                    </div>
            </Modal>
        </div>
    );
  }
}

export default ModalYesOrNoTwoString;
