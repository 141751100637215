import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
class ModalYesOrNo extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      }
      this.canvasRef = React.createRef();
    }

    static propTypes = {
        showModal: PropTypes.bool,
        positiveModalYesOrNo: PropTypes.func,
        negativeModalYesOrNo: PropTypes.func,
        titleModalYesOrNo: PropTypes.string,
        subtitleModalYesOrNo: PropTypes.string,
        buttonPositiveModalYesOrNo: PropTypes.string,
        data: PropTypes.object,
        position: PropTypes.string
    }
    
    static defaultProps = {
        showModal:false,
    }

    getClasses = () =>{
        const useStyles = makeStyles((theme) => ({
            paper: {
              position: 'absolute',
              width: 400,
              backgroundColor: theme.palette.background.paper,
              border: '2px solid #000',
              boxShadow: theme.shadows[5],
              padding: theme.spacing(2, 4, 3),
            },
          }));
          return useStyles
    }
  render(){

    const { showModal, positiveModalYesOrNo, negativeModalYesOrNo, titleModalYesOrNo, subtitleModalYesOrNo, buttonPositiveModalYesOrNo, position, data } = this.props

    return (
        <div >
            <Modal
                open={showModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                    <div style={{width:400,alignSelf:'center'}} className={[this.getClasses()," modal-dialog modal-dialog-centered"]}>
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">{titleModalYesOrNo}</h5>
                        </div>
                        <div class="modal-body">
                            <strong className="text-muted d-block mb-2">{subtitleModalYesOrNo}</strong>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={()=>negativeModalYesOrNo()}>Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={()=>positiveModalYesOrNo(position, data)}>{buttonPositiveModalYesOrNo}</button>
                        </div>
                        </div>
                    </div>
            </Modal>
        </div>
    );
  }
}

export default ModalYesOrNo;
