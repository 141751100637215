import { 
    ADD_INTENT,
    UPDATE_INTENT,
    DELETE_INTENT,
    READ_ALL_INTENT
 } from "../actions/types";


const initialState = {
  loading:false,
  addIntentResponse:null,
  updateIntentResponse:null,
  deleteIntentResponse:null,
  readIntentResponse:null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INTENT:
      return {
        ...state,
        loading:true,
        addIntentResponse: action.data
      }
    break;
    case UPDATE_INTENT:
        return {
            ...state,
            loading:true,
            updateIntentResponse: action.data
          }
    break;
    case DELETE_INTENT:
        return {
            ...state,
            loading:true,
            deleteIntentResponse: action.data
          }
      break;  
      case READ_ALL_INTENT:
        return {
            ...state,
            loading:true,
            readIntentResponse: action.data
          }
      break;  
    default:
      return state;
  }
}