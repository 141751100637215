import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button
} from "shards-react";
import { connect } from "react-redux";
import { siginRequest, setUserData } from "../../redux/actions/UserAction";
import { Redirect  } from "react-router-dom";


class SigninForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      redirect:false
    }
  }

  componentDidUpdate = (prevProps) => {
    const {  signinResponse } = prevProps
    const {
      loading: newLoading,
      signinResponse: newSigninResponse,
      setUserData
    } = this.props

    if (signinResponse !== newSigninResponse && newSigninResponse !== null) {
      console.log('newSigninResponse', newSigninResponse.status)
      if(newSigninResponse.status){
        setUserData(newSigninResponse.data[0])
        this.setState({
          redirect:true
        })
      }
    }
  }

  signInHandler = () => {
      const { siginRequest } = this.props
      const { email, password } = this.state
      const params = {
        email: email,
        password:password
      }

      siginRequest(params)
  }
  render() {
    const { email, password, redirect } = this.state
    console.log('this.props', this.props.signinResponse)
    console.log('redirect', redirect)
    if(redirect===false){
    return (
  <div>
    <InputGroup seamless className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">person</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput 
        type="email"
        required 
        value={email} 
        placeholder='Email'
        onChange={(text) => this.setState({email:text.target.value})} 
      />
    </InputGroup>

    <InputGroup seamless className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">lock</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        type="password"
        value={password}
        required
        placeholder='Password'
        onChange={(text) => this.setState({password:text.target.value})}
      />
    </InputGroup>

    <InputGroup seamless className="mb-3">
        <Button size="sm" theme="primary" className="mb-2 mr-1"
          onClick={()=> this.signInHandler()}
        >
          Signin
        </Button>
    </InputGroup>
  </div>
      );
    }else{
      console.log('got to home', redirect)
      return <Redirect to={'/'} />
    }
    }
  }
  const mapStateToProps = state => ({
    loading: state.user.loading,
    signinResponse: state.user.signinResponse,
    withRouter : state.withRouter
  });

  const mapDispatchToProps = {
    siginRequest,
    setUserData
  };
export default connect(mapStateToProps, mapDispatchToProps)(SigninForm)
