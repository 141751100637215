import { 
    ADD_KEYWORD,
    UPDATE_KEYWORD,
    DELETE_KEYWORD,
    READ_ALL_KEYWORD
 } from "../actions/types";


const initialState = {
  loading:false,
  addKeywordResponse:null,
  updateKeywordResponse:null,
  deleteKeywordResponse:null,
  readKeywordResponse:null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_KEYWORD:
      return {
        ...state,
        loading:true,
        addKeywordResponse: action.data
      }
    break;
    case UPDATE_KEYWORD:
        return {
            ...state,
            loading:true,
            updateKeywordResponse: action.data
        }
    break;
    case DELETE_KEYWORD:
        return {
            ...state,
            loading:true,
            deleteKeywordResponse: action.data
          }
      break;  
      case READ_ALL_KEYWORD:
        return {
            ...state,
            loading:true,
            readKeywordResponse: action.data
          }
      break;  
    default:
      return state;
  }
}