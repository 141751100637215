import { 
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    READ_ALL_PRODUCT
} from './types';
import ApiService from '../../services/ApiServices';

export const readAllProduct  = (token) => async (dispatch) => {
  let data;
    try {
      const res = await ApiService.readAllProduct(token);
      data = res.data;
    } catch (err) {
      data=err.response.data;
    }
    dispatch({
      type: READ_ALL_PRODUCT,
      data: data,
    });
    return Promise.resolve('WOOOOOOOOOOOY');
  };


export const addProduct  = (token,data) => async (dispatch) => {
  try {
    const res = await ApiService.addProduct(token,data);
   
    dispatch({
      type: ADD_PRODUCT,
      data: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateProduct  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.updateProduct(token,data);
     
      dispatch({
        type: UPDATE_PRODUCT,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const deleteProduct  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.deleteProduct(token,data);
     
      dispatch({
        type: DELETE_PRODUCT,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };