import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
    Row,
    Col,
    Form,
    FormInput,
    FormSelect,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "shards-react";

class ModalYesOrNoOneString extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          value: null
      }
      this.canvasRef = React.createRef();
    }

    static propTypes = {
        showModal: PropTypes.bool,
        negativeModalOneString: PropTypes.func,
        positiveModalOneString: PropTypes.func,
        data: PropTypes.object,
        position: PropTypes.string,

        titleModalOneString: PropTypes.string,
        subtitleModalOneString: PropTypes.string,
        placeholderModalOneStringA: PropTypes.string,
 
    }
    
    static defaultProps = {
        showModal:false,
    }

    getClasses = () =>{
        const useStyles = makeStyles((theme) => ({
            paper: {
              position: 'absolute',
              width: 400,
              backgroundColor: theme.palette.background.paper,
              border: '2px solid #000',
              boxShadow: theme.shadows[5],
              padding: theme.spacing(2, 4, 3),
            },
          }));
          return useStyles
    }
  render(){

    const { 
        showModal, 
        negativeModalOneString, 
        titleModalOneString, 
        subtitleModalOneString,
        placeholderModalOneStringA,
        positiveModalOneString,
        data,
        position 
    } = this.props
        const { value } = this.state
    return (
        <div >
            <Modal
                open={showModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                    <div style={{width:400,alignSelf:'center'}} className={[this.getClasses()," modal-dialog modal-dialog-centered"]}>
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">{titleModalOneString}</h5>
                        </div>
                        <div class="modal-body">
                            <Row style={{width:'100%'}}>
                                <strong className="text-muted d-block mb-2">{subtitleModalOneString}</strong>
                                <Form style={{width:'100%'}}>
                                    <FormGroup>

                                        <FormInput
                                            placeholder={placeholderModalOneStringA}
                                            require
                                            value={value}
                                            onChange={(text) => this.setState({value:text.target.value})}
                                            />
                                    </FormGroup>
                                </Form>
                            </Row>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={()=>negativeModalOneString()}>Close</button>
                            <button type="button" class="btn btn-primary" onClick={()=>positiveModalOneString(position,{data:data, value:value})}>Save changes</button>
                        </div>
                        </div>
                    </div>
            </Modal>
        </div>
    );
  }
}

export default ModalYesOrNoOneString;
