import Axios from "axios";
const baseUrl = 'https://posapi.dwikalestari.co.id/api/v1/'
//---------------------------------------------------------------------------------- AUTH
const logout = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json" , Authorization: 'Bearer '+token}
    };

    return Axios.post( 
        baseUrl+'/auth/logout',
        data,
        config
    );
};

const signin = (data) => {
    const config = {
        headers: { "Content-type": "application/json" }
    };
    return Axios.post( 
        baseUrl+'users/login',
        data,
        config
      );
};
//---------------------------------------------------------------------------------- CATEGORY
const readAllProduct = (token) => {
    const config = {
        headers: { "Content-type": "application/json" , Authorization: 'Bearer '+token}
    };
    return Axios.post( 
        baseUrl+'users/listproducts',
        {},
        config
      );
};
const addProduct = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'users/addproduct',
        data,
        config
      );
};

const updateProduct = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'users/updateproduct',
        data,
        config
      );
};
const deleteProduct = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'users/deleteproduct',
        data,
        config
      );
};
const readProductBy = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'users/detailproduct',
        data,
        config
      );
};

//---------------------------------------------------------------------------------- INTENT
const readAllIntent = (token) => {
    const config = {
        headers: { "Content-type": "application/json" , Authorization: 'Bearer '+token}
    };
    return Axios.get( 
        baseUrl+'/category/readIntent',
        config
      );
};
const addIntent = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/intent/addIntent',
        data,
        config
      );
};

const updateIntent = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/intent/updateIntent',
        data,
        config
      );
};
const deleteIntent = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/intent/deleteIntent',
        data,
        config
      );
};
const readIntentBy = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/intent/readIntentBy',
        data,
        config
      );
};


//---------------------------------------------------------------------------------- KEYWORD
const readAllKeyword = (token) => {
    const config = {
        headers: { "Content-type": "application/json" , Authorization: 'Bearer '+token}
    };
    return Axios.get( 
        baseUrl+'/keywords/readKeyword',
        config
      );
};
const addKeyword = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };

    return Axios.post( 
        baseUrl+'/keywords/addKeywords',
        data,
        config
      );
};

const updateKeyword = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/keywords/updateKeywords',
        data,
        config
      );
};
const deleteKeyword = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/keywords/deleteKeywords',
        data,
        config
      );
};
const readKeywordBy = (token,data) => {
    const config = {
        headers: { "Content-type": "application/json", Authorization: 'Bearer '+token }
    };
    return Axios.post( 
        baseUrl+'/keywords/readKeywordsBy',
        data,
        config
      );
};
const ApiService = {

    signin,
    logout,

    deleteProduct,
    updateProduct,
    readAllProduct,
    readProductBy,
    addProduct,

    deleteIntent,
    updateIntent,
    readAllIntent,
    readIntentBy,
    addIntent,

    deleteKeyword,
    updateKeyword,
    readAllKeyword,
    readKeywordBy,
    addKeyword,
};

export default ApiService;