import { 
    ADD_KEYWORD,
    UPDATE_KEYWORD,
    DELETE_KEYWORD,
    READ_ALL_KEYWORD
} from './types';
import ApiService from '../../services/ApiServices';

export const readAllKeyword  = (token) => async (dispatch) => {
    try {
      const res = await ApiService.readAllKeyword(token);
      dispatch({
        type: READ_ALL_KEYWORD,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };


export const addKeyword  = (token,data) => async (dispatch) => {
  console.log('addKeyword REQUEST', data)
  try {
    const res = await ApiService.addKeyword(token,data);

    console.log('addKeyword RESPONSE', res.data)
    dispatch({
      type: ADD_KEYWORD,
      data: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateKeyword  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.updateKeyword(token,data);

      dispatch({
        type: UPDATE_KEYWORD,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const deleteKeyword  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.deleteKeyword(token,data);

      dispatch({
        type: DELETE_KEYWORD,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };