import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
  FormInput,
  Col,
} from "shards-react";
import RangeDatePicker from "./RangeDatePicker";
import ModalYesOrNo from '../modal/modalYesOrNo';
import Tooltip from '@material-ui/core/Tooltip';

import { connect } from "react-redux";
import { addProduct } from "../../redux/actions/ProductsAction";

class SidebarCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: null,
      startDate: null,
      endDate: null,

      showModalYesOrNo: false,
      titleModalYesOrNo:null, 
      subtitleModalYesOrNo:null, 
      placeholderModalYesOrNo:null,
      buttonPositiveModalYesOrNo:null 
    }
    this.canvasRef = React.createRef();
  }
  static propTypes = {
    title: PropTypes.string,
}

static defaultProps = {
  title:'Control Panel',
}

showModaYesOrNoController =(titleModalYesOrNo, subtitleModalYesOrNo, buttonPositiveModalYesOrNo)=>{
  this.setState({
    titleModalYesOrNo:titleModalYesOrNo,
    subtitleModalYesOrNo:subtitleModalYesOrNo,
    buttonPositiveModalYesOrNo:buttonPositiveModalYesOrNo,
    showModalYesOrNo: true
  })

}
positiveModalYesOrNo =()=>{
  const { token, addProduct } = this.props
  const { categoryName } = this.state
  let params = {
    category_intent_name: categoryName
  }
  addProduct(token, params)
  this.setState({
    categoryName: null,
    showModalYesOrNo: false
  })
}
negativeModalYesOrNo =()=>{
  this.setState({
    showModalYesOrNo: false
  })
}
render(){
  const { title } = this.props
  const { 
    categoryName,
    showModalYesOrNo,
    titleModalYesOrNo, 
    subtitleModalYesOrNo,
    buttonPositiveModalYesOrNo  } = this.state
  return(
    <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-auto">{title}</h6>
    </CardHeader>
    <CardBody className="p-0">
      <ListGroup flush>
        <ListGroupItem className="d-flex px-3">
          <InputGroup className="ml-auto">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <RangeDatePicker />
            </Col>
            <FormInput 
              placeholder="New category" 
              value={categoryName}
              onChange={(text) => this.setState({categoryName:text.target.value})} 
              />
            
            <Tooltip title="Add New Category">
              <Button theme="grey" 
                onClick={()=> this.showModaYesOrNoController("Add new category for "+categoryName,"Are you sure?","Save")}
                style={{borderColor:'#d3d3d3'}} 
                className="pt-auto px-2 ml-2" >
                <i className="material-icons">add</i>
              </Button>
            </Tooltip>
            
          </InputGroup>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
    <ModalYesOrNo 
          titleModalYesOrNo={titleModalYesOrNo}
          subtitleModalYesOrNo={subtitleModalYesOrNo}
          showModal={showModalYesOrNo} 
          positiveModalYesOrNo={this.positiveModalYesOrNo}
          negativeModalYesOrNo={this.negativeModalYesOrNo} 
          buttonPositiveModalYesOrNo={buttonPositiveModalYesOrNo}/>
  </Card>
  )
}
}
const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  withRouter : state.withRouter
});

const mapDispatchToProps = {
  addProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarCategories)

