import { 
    ADD_INTENT,
    UPDATE_INTENT,
    DELETE_INTENT,
    READ_ALL_INTENT
} from './types';
import ApiService from '../../services/ApiServices';

export const readAllIntent  = (token) => async (dispatch) => {
    try {
      const res = await ApiService.readAllIntent(token);
      dispatch({
        type: READ_ALL_INTENT,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };


export const addIntent  = (token,data) => async (dispatch) => {
  try {
    const res = await ApiService.addIntent(token,data);

    dispatch({
      type: ADD_INTENT,
      data: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateIntent  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.updateIntent(token,data);

      dispatch({
        type: UPDATE_INTENT,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const deleteIntent  = (token,data) => async (dispatch) => {
    try {
      const res = await ApiService.deleteIntent(token,data);

      dispatch({
        type: DELETE_INTENT,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };