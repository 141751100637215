/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormInput,
  FormRadio,
  FormTextarea,
  FormSelect,
  timeoutsShape,
  CardColumns,
  CardGroup,
  CardTitle,
  CardFooter,
  Button
} from "shards-react";
import classNames from "classnames"
import Dropzone from "react-dropzone"
import Select from 'react-select'
import MultipleValueTextInput from 'react-multivalue-text-input'
import { connect } from "react-redux";
import { readAllProduct, addProduct } from "../redux/actions/ProductsAction";
import PageTitle from "../components/common/PageTitle"
import { Snackbar } from "@material-ui/core";

class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      cid:[],
      name:'',
      category:'',
      images:[],
      video:'',
      specification:{},
      description:'',
      group:'',
      condition:0,
      description:'',
      variant_test:[],
      variant_sub:[],
      variant_sub_sku:[],
      variant_sub_stock:[],
      variant_sub_capital_price:[],
      variant_sub_price:[],
      variant_sub_price_reseller:[],

      harga_modal_single:0,
      harga_jual_single:0,
      harga_jual_reseller_single:0,
      stock_single:0,
      sku_single:'',
      min_price:0,
      max_price:0,
      image_guide:'',
      min_order:1,
      order_group:[],
      weight:0,
      size:{},
      assurance:0,
      order_type:0,
      ppn:11,
      stock:[],
      models:[],
      colors:[],
      sizes:[],
      active:1,
      messageApi:null,
      categoryProductList:[
        { value: 'Daster Homedress', label: 'Daster Homedress' },
        { value: 'Baju Muslim Wanita', label: 'Busana Muslim Wanita' },
        { value: 'Baju Muslim Pria', label: 'Busana Muslim Pria' },
        { value: 'Kerudung', label: 'Kerudung' },
        { value: 'Sarung', label: 'Sarung' },
        { value: 'Bed Cover', label: 'Bed Cover' }
      ],
      addProductSnackBar:false
    };
  }
  componentDidMount = () => {
    const { token, readAllProduct } = this.props
    console.log(token)
    readAllProduct(token);
  }
  componentDidUpdate = (prevProps) => {
    const { readProductResponse, addProductResponse } = this.props
    console.log(addProductResponse)
    if (prevProps.addProductResponse !== this.props.addProductResponse) {
      this.setState({
        messageApi:addProductResponse.message,
        addProductSnackBar:true
      })
        if(addProductResponse.code!=401){


        }else{
          this.setState({isLogin:false})  
        }

    }
  }
  addProduct=()=>{
    const { addProduct, token } = this.props
    let params={
      images:JSON.stringify(this.state.images),
      name:this.state.name,
      video:this.state.video,
      category:this.state.category,

      sku:this.state.sku_single,
      stock:this.state.stock_single,
      min_price:this.state.harga_jual_single,
      max_price:this.state.harga_jual_single,

      capital_price:this.state.harga_modal_single,
      price:this.state.harga_jual_single,
      price_reseller:this.state.harga_jual_reseller_single,

      min_order:this.state.min_order,
      variant:'',
      assurance:1,
      order_type:1,
      size:'{"P":5,"L":30,"T":10}',
      wieght:400.00,
      order_group:{},
      image_guide:'https://cf.shopee.co.id/file/66bbc54b3ccc227aafd18b0c3b78c446',
      condition:1,
      group:this.state.category
    }
    console.log(token)
    console.log(params)
    addProduct(token,params);
  }

  addProductVariant=()=>{
    const { addProduct, token } = this.props
    let variant=[]

    let params={
      images:JSON.stringify(this.state.images),
      name:this.state.name,
      video:this.state.video,
      category:this.state.category,
      min_price:0,
      max_price:0,
      stock:0,
      min_order:this.state.min_order,
      variant:variant,
      assurance:1,
      order_type:1,
      size:'{"P":5,"L":30,"T":10}',
      wieght:400.00,
      order_group:{},
      min_order:1,
      image_guide:'https://cf.shopee.co.id/file/66bbc54b3ccc227aafd18b0c3b78c446',
      condition:1,
      group:this.state.category
    }
    let min_price=[];
    let max_price=[];

      this.state.variant_sub[0].variant_value.map((v,i)=>{

        variant.push({
          id:i,
          name:this.state.variant_sub[0].variant_name,
          value:v,
          detail:[]
        })
        let p=[]

        this.state.variant_sub[1].variant_value.map((v,j)=>{
          params.stock=params.stock+parseInt(this.state.variant_sub_stock[i][j])
          p.push(parseInt(this.state.variant_sub_price[i][j]))
          params.stock=params.stock+parseInt(this.state.variant_sub_stock)
          variant[i].detail.push({
            id:j,
            variant_id:i+'-'+j,
            name:this.state.variant_sub[1].variant_name,
            value:v,
            sku:this.state.variant_sub_sku[i][j],
            stock:this.state.variant_sub_stock[i][j],
            capital_price:this.state.variant_sub_capital_price[i][j],
            price:this.state.variant_sub_price[i][j],
            reseller_price:this.state.variant_sub_price_reseller[i][j]
          })
        })

        min_price.push(Math.min(...p))
        max_price.push(Math.max(...p))
        params.min_price=Math.min(...min_price)
        params.max_price=Math.max(...max_price)

      })

    console.log(token)
    console.log(params)
    params.variant=JSON.stringify(params.variant);
    addProduct(token,params);
  }
  getBase64=(file, cb)=> {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

  handleDrop=(event,id)=> {
    this.getBase64(event[0], (result) => {
      let res={"PID":"","ID":id,"IMAGE":result}
      let images=this.state.images;
      images[id]=res;
      this.setState({images:images})
    });
    console.log(this.state.images)
  }
  onChangeVariantSubAdd=(text,name)=>{
    const variant_sub = this.state.variant_sub
    let variant_sub_temp=[];
    let flag =0;
    if(variant_sub.length>0){//variant kedua
      variant_sub_temp = variant_sub.map((data_variant, index)=>{
        if(data_variant.variant_name === name){
          flag=1;
          data_variant.variant_value=text;

          return {
            variant_name:data_variant.variant_name,
            variant_value:text

          }
        }

        return data_variant
      })
      this.setState({variant_sub:variant_sub_temp})
      this.setState({variant_sub_sku:new Array(this.state.variant_sub[0].variant_value.length+1).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_stock:new Array(this.state.variant_sub[0].variant_value.length+1).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_capital_price:new Array(this.state.variant_sub[0].variant_value.length+1).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price:new Array(this.state.variant_sub[0].variant_value.length+1).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price_reseller:new Array(this.state.variant_sub[0].variant_value.length+1).fill('').map(() => new Array(text.length).fill(''))})
      console.log('=============VARIANT KEDUA=============')
      console.log(variant_sub)
      console.log(this.state.variant_sub_sku)
      console.log(this.state.variant_sub_stock)
      console.log(this.state.variant_sub_capital_price)
      console.log(this.state.variant_sub_price)
      console.log(this.state.variant_sub_price_reseller)
    }else{//variant sama tambah value
      flag=1
      let params = {
        variant_name:name,
        variant_value:text
      }
      variant_sub_temp.push(params)
      this.setState({variant_sub:variant_sub_temp})
      this.setState({variant_sub_sku:new Array(variant_sub_temp.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_stock:new Array(variant_sub_temp.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_capital_price:new Array(variant_sub_temp.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price:new Array(variant_sub_temp.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price_reseller:new Array(variant_sub_temp.length).fill('').map(() => new Array(text.length).fill(''))})
    
      console.log('=============VARIANT SAMA TAMBAH VALUE=============')
      console.log(variant_sub)
      console.log(this.state.variant_sub_sku)
      console.log(this.state.variant_sub_stock)
      console.log(this.state.variant_sub_capital_price)
      console.log(this.state.variant_sub_price)
      console.log(this.state.variant_sub_price_reseller)
    }
    if(flag==0){ //variant pertama
      let params = {
        variant_name:name,
        variant_value:text
      }
      this.setState({ variant_sub: [...this.state.variant_sub, ...[params] ] })
      this.setState({variant_sub_sku:new Array(variant_sub.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_stock:new Array(variant_sub.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_capital_price:new Array(variant_sub.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price:new Array(variant_sub.length).fill('').map(() => new Array(text.length).fill(''))})
      this.setState({variant_sub_price_reseller:new Array(variant_sub.length).fill('').map(() => new Array(text.length).fill(''))})

      console.log('=============VARIANT PERTAMA=============')
      console.log(variant_sub)
      console.log(this.state.variant_sub_sku)
      console.log(this.state.variant_sub_stock)
      console.log(this.state.variant_sub_capital_price)
      console.log(this.state.variant_sub_price)
      console.log(this.state.variant_sub_price_reseller)
    }

   
  }
  onChangeStocks=(text,name,i,j)=>{
    console.log('EDIT STOCK',text+' '+name+' '+i+' '+j)
    let temp_stock = this.state.variant_sub_stock;
    temp_stock[i][j]=parseInt(text);
    this.setState({variant_sub_stock:temp_stock})
  }
  onChangeTextArea=(text,name,i,j)=>{
    //description
    if(name=="video"){
      this.setState({video:text})
    }else if(name=="name"){
      this.setState({name:text})
    }else if(name=="description"){
      this.setState({description:text})
    }else if(name=="harga_modal_single"){
      this.setState({harga_modal_single:parseInt(text)})
    }else if(name=="harga_jual_single"){
      this.setState({harga_jual_single:parseInt(text)})
    }else if(name=="stock_single"){
      this.setState({stock_single:parseInt(text)})

    }else if(name=="sku_single"){
      this.setState({sku_single:text})

    }else if(name=="harga_jual_reseller_single"){
      this.setState({harga_jual_reseller_single:parseInt(text)})

    }else if(name==="sku_array"){
      let temp_sku = this.state.variant_sub_sku;
      temp_sku[i][j]=text;
      this.setState({variant_sub_sku:temp_sku})     

    }else if(name==="capital_price_array"){
      let temp = this.state.variant_sub_capital_price;
      temp[i][j]=parseInt(text);
      this.setState({variant_sub_capital_price:temp})

    }else if(name==="price_array"){
      let temp = this.state.variant_sub_price;
      temp[i][j]=parseInt(text);
      this.setState({variant_sub_price:temp})
    }else if(name==="price_reseller_array"){
      let temp = this.state.variant_sub_price_reseller;
      temp[i][j]=parseInt(text);
      this.setState({variant_sub_price_reseller:temp})
    }
    else if(name==="min_order"){
      this.setState({min_order:parseInt(text)})
    }
  }
  formSelected=(value,d)=>{
    if(d=="category"){
      this.setState({dropdownCategory:!this.state.dropdownCategory, category:value})
    }else if(d=="format_size"){
      this.setState({dropdownFormatSize:value})
    }
  }
  renderListVariant=(index,datas)=>{
    return(
      datas.variant_value.map((variant_test)=>(
        <tr>
          <td rowSpan={datas.variant_value.length}>{variant_test}</td>
        </tr>
      ))
    )
  }
  closeSnackBar=(flag)=>{
    if(flag=='addProductSnackBar'){
      this.setState({addProductSnackBar:false})
    }
  }
  render() {
    const variant_test = this.state.variant_test
    const variant_sub = this.state.variant_sub

    return (
      <Container fluid className="main-content-container" style={{height:'auto'}}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Tambah Produk" subtitle="" className="text-sm-left" />
        </Row>

        {/* Upload Produk */}
        <Row className="p-0">
          <Col sm="12" className="mb-4 w-100" key={1}>
            <Card large className="card-post card-post--aside card-post--1">
              <CardBody>
                <Col>
                  <Row>
                    <Col md="2">
                      <Row className="d-flex" >
                        <h6 style={{fontWeight:'bold', alignSelf:'flex-start'}}>Upload Produk</h6>
                      </Row>
                      <Row className="d-flex" >
                        <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                          <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Foto produk</h6>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                          <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                        </div>
                      </Row>
                      
                      <Row className="w-100 mt-2" size="sm">
                          <small>
  Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px).
  <br />
  <br />
  Pilih foto produk atau tarik dan letakkan hingga 5 foto sekaligus di sini. Cantumkan min. 3 foto yang menarik agar produk semakin menarik pembeli.
                          </small>
                      </Row>
                    </Col>
                    <Row className="d-flex justify-content-center align-content-center" style={{alignContent:'center', alignSelf:'center', marginLeft:'auto', marginRight:'auto'}}>

                      <Col style={{textAlign:'center'}}>
                        <div style={{borderStyle: 'dashed',borderWidth:0.5, borderRadius:8, flexDirection:'column',marginLeft:'auto', marginRight:'auto'}} className="file-manager-cards__dropzone">
                        <Dropzone className="dropzone" style={{borderColor:'black'}} onDrop={(e)=>this.handleDrop(e,0)}>
                              {({ getRootProps, getInputProps, isDragActive }) => {
                                if(this.state.images[0]==null){
                                  return (
                                    <div style={{backgroundImage:'url("https://assets.tokopedia.net/assets-tokopedia-lite/v2/icarus/kratos/8d58e463.svg")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }else{
                                  return (
                                    <div style={{backgroundImage:'url("'+this.state.images[0].IMAGE+'")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize:'cover', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }}}
                            </Dropzone>
                        </div>
                        <h6>Foto Utama</h6>
                      </Col>

                      <Col style={{textAlign:'center'}}>
                        <div style={{borderStyle: 'dashed',borderWidth:0.5, borderRadius:8, flexDirection:'column',marginLeft:'auto', marginRight:'auto'}} className="file-manager-cards__dropzone">
                        <Dropzone className="dropzone" style={{borderColor:'black'}} onDrop={(e)=>this.handleDrop(e,1)}>
                              {({ getRootProps, getInputProps, isDragActive }) => {
                                if(this.state.images[1]==null){
                                  return (
                                    <div style={{backgroundImage:'url("https://assets.tokopedia.net/assets-tokopedia-lite/v2/icarus/kratos/8d58e463.svg")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }else{
                                  return (
                                    <div style={{backgroundImage:'url("'+this.state.images[1].IMAGE+'")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize:'cover', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                  
                                }
                              }}
                            </Dropzone>
                        </div>
                        <h6>Foto 2</h6>
                      </Col>

                      <Col style={{textAlign:'center'}}>
                        <div style={{borderStyle: 'dashed',borderWidth:0.5, borderRadius:8, flexDirection:'column',marginLeft:'auto', marginRight:'auto'}} className="file-manager-cards__dropzone">
                        <Dropzone className="dropzone" style={{borderColor:'black'}} onDrop={(e)=>this.handleDrop(e,2)}>
                              {({ getRootProps, getInputProps, isDragActive }) => {
                                if(this.state.images[2]==null){
                                  return (
                                    <div style={{backgroundImage:'url("https://assets.tokopedia.net/assets-tokopedia-lite/v2/icarus/kratos/8d58e463.svg")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }else{
                                  return (
                                    <div style={{backgroundImage:'url("'+this.state.images[2].IMAGE+'")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize:'cover', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                  
                                }
                              }}
                            </Dropzone>
                        </div>
                        <h6>Foto 3</h6>
                      </Col>

                      <Col style={{textAlign:'center'}}>
                        <div style={{borderStyle: 'dashed',borderWidth:0.5, borderRadius:8, flexDirection:'column',marginLeft:'auto', marginRight:'auto'}} className="file-manager-cards__dropzone">
                        <Dropzone className="dropzone" style={{borderColor:'black'}} onDrop={(e)=>this.handleDrop(e,3)}>
                              {({ getRootProps, getInputProps, isDragActive }) => {
                                if(this.state.images[3]==null){
                                  return (
                                    <div style={{backgroundImage:'url("https://assets.tokopedia.net/assets-tokopedia-lite/v2/icarus/kratos/8d58e463.svg")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }else{
                                  return (
                                    <div style={{backgroundImage:'url("'+this.state.images[3].IMAGE+'")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize:'cover', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                  
                                }
                              }}
                            </Dropzone>
                        </div>
                        <h6>Foto 4</h6>
                      </Col>

                      <Col style={{textAlign:'center'}}>
                        <div style={{borderStyle: 'dashed',borderWidth:0.5, borderRadius:8, flexDirection:'column',marginLeft:'auto', marginRight:'auto'}} className="file-manager-cards__dropzone">
                        <Dropzone className="dropzone" style={{borderColor:'black'}} onDrop={(e)=>this.handleDrop(e,4)}>
                              {({ getRootProps, getInputProps, isDragActive }) => {
                                if(this.state.images[4]==null){
                                  return (
                                    <div style={{backgroundImage:'url("https://assets.tokopedia.net/assets-tokopedia-lite/v2/icarus/kratos/8d58e463.svg")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                }else{
                                  return (
                                    <div style={{backgroundImage:'url("'+this.state.images[4].IMAGE+'")',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize:'cover', alignItems:'center', placeContent:'center',width:140, height:140,marginLeft:'auto', marginRight:'auto'}}
                                      {...getRootProps()}
                                      className={classNames("dropzone", {
                                        "dropzone--isActive": isDragActive
                                      })} >
                                      <input {...getInputProps()} />
                                    </div>
                                  );
                                  
                                }
                              }}
                            </Dropzone>
                        </div>
                        <h6>Foto 5</h6>
                      </Col>

                    </Row>
                  </Row>
<br />
<br />
                  <Row>
                    <Col>
                      <Row className="d-flex" md="4">
                        <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                          <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Video produk</h6>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                          <small style={{alignSelf:'center',fontWeight:'bold'}}>Beta</small>
                        </div>
                      </Row>
                      
                      <Row className="w-25 mt-2" md="4" size="sm">
                          <small> Alamat URL Video unggahan. </small>
                      </Row>
                    </Col>
                    <Col  md="8" >
                      <Row className="d-flex" style={{marginTop:30,height:20}}>
                        <FormInput value={this.state.video} onChange={(text)=>this.onChangeTextArea(text.target.value,"video",0,0)} />
                      </Row>
                    </Col>

                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Informasi Produk */}
        <Row className="p-20" >
          <Col sm="12" className="mb-4 w-100" key={1} >
            <Card large className="card-post card-post--aside card-post--1" style={{paddingBottom:20}}>

              <CardBody>
                <Col>
                  <Row>
                      <Col md="4">
                      <Row className="d-flex" >
                        <h6 style={{fontWeight:'bold', alignSelf:'flex-start'}}>Informasi Produk</h6>
                      </Row>
                        <Row className="d-flex" >
                          <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                            <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Kategori produk</h6>
                          </div>
                          <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                            <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                          </div>
                        </Row>
                        <Row className="w-50 mt-2" size="sm">
                            <small></small>
                        </Row>
                      </Col>

                      <Col  md="8">
                        <Row className="w-100 mt-4" size="sm">
                          <Select className="w-100" size="sm" placeholder="Pilih Kategori" onChange={(e)=>this.formSelected(e.value,"category")} options={this.state.categoryProductList} />
                        </Row>
                      </Col>
                    </Row>
<br />
<br />
                  <Row >
                    <Col>
                      <Row className="d-flex" >
                        <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                          <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Nama produk</h6>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                          <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                        </div>
                      </Row>
                      <Row className="w-50 mt-2" size="sm">
                          <small>
                          Cantumkan min. 40 karakter agar semakin menarik dan mudah ditemukan oleh pembeli, terdiri dari jenis produk, merek, dan keterangan seperti warna, bahan, atau tipe.
                          </small>
                      </Row>
                    </Col>

                    <Col md="8">
                      <Row className="d-flex" style={{height:20}}>
                        <FormInput placeholder="Contoh: Daster Motif Korea Terbaru" value={this.state.name} onChange={(text)=>this.onChangeTextArea(text.target.value,"name",0,0)} />
                        <small>0/70</small>
                      </Row>
                      
                    </Col>
                  </Row>
                  <br />
<br />
                  <Row >
                    <Col>
                      <Row className="d-flex" >
                        <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                          <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Deskripsi produk</h6>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                          <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                        </div>
                      </Row>
                      <Row className="w-50 mt-2" size="sm">
                          <small>
                          Cantumkan sedetail mungkin deskripsi produk anda.
                          </small>
                      </Row>
                    </Col>

                    <Col md="8">
                      <Row className="d-flex" style={{height:20}}>
                        <FormTextarea value={this.state.description} onChange={(text)=>this.onChangeTextArea(text.target.value,"description",0,0)} />
                        
                        <small>0/70</small>
                      </Row>
                      
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Variant Produk */}
        <Row className="p-20" style={{height:'auto'}}>
          <Col sm="12" className="mb-4 w-100" key={1} style={{height:'auto'}}>
            <Card style={{height:'auto'}}>
            <CardBody style={{height:'auto'}}>
                <Col style={{height:'auto'}}>
                  <Row style={{height:'auto'}}>
                      <Col md="4" style={{height:'auto'}}>
                      <Row className="d-flex" style={{height:'auto'}}>
                        <h6 style={{fontWeight:'bold', alignSelf:'flex-start'}}>Variasi Produk</h6>
                      </Row>
                        <Row className="d-flex" >
                          <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                            <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Tambah Variasi</h6>
                          </div>
                          <div className="d-flex justify-content-center" >
                            <small></small>
                          </div>
                        </Row>
                        <Row className="w-50 mt-2" size="sm">
                            <small>Maks 2</small>
                        </Row>


                      </Col>

                      <Col md="8" >
                    <MultipleValueTextInput
                        style={{height:40, width:'100%'}}
                        className=" form-control mt-5"
                        onItemAdded={(item, allItems)=> {if(allItems.length<=2){this.setState({variant_test:allItems})}}}
                        onItemDeleted={(item, allItems)=> this.setState({variant_test:allItems})}
                        name="models"
                        placeholder="Tekan Enter untuk input model. Contoh: Polos, Bunga Mawar, Kotak-Kotak." />
                      
                    </Col>

                    </Row>
                    <br />
                    <br />
                    {variant_test.length >0 ?
                      <>
                        {this.state.variant_test.map((variant_test)=>(
                          <>
                    <Row >
                      <Col>
                        <Row className="d-flex" >
                          <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                            <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>{variant_test}</h6>
                          </div>
                          <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                            <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                          </div>
                        </Row>
                        <Row className="w-50 mt-2" size="sm">
                            <small>
                              Cantumkan isi.
                            </small>
                        </Row>
                      </Col>

                      <Col md="8" >
                        <MultipleValueTextInput
                        style={{height:40, width:'100%'}}
                        className=" form-control mt-5"
                            onItemAdded={(item, allItems)=> this.onChangeVariantSubAdd(allItems,variant_test)}
                            onItemDeleted={(item, allItems)=> this.onChangeVariantSubAdd(allItems,variant_test)}
                            name="colors"
                            placeholder="Tekan Enter untuk input isi. Contoh: Merah, Biru, s, m, l, xl." />
                        
                      </Col>
                    </Row>
                    <br />
                    <br />
                          </>
                        ))}

                      </>
                      :
                      <>

                        <Row >
                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>SKU</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan SKU.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 15000" value={this.state.sku_single} onChange={(text)=>this.onChangeTextArea(text.target.value,"sku_single",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>                       
                        </Row>
                        <br />
                        <br /> 
                        <Row >

                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Stok</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan stok.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 20000" value={this.state.stock_single} onChange={(text)=>this.onChangeTextArea(text.target.value,"stock_single",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>
                        </Row>

                        <br />
                        <br /> 
                        <Row >
                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Harga Modal</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan harga modal.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 20000" value={this.state.harga_modal_single} onChange={(text)=>this.onChangeTextArea(text.target.value,"harga_modal_single",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>
                        </Row>

                        <br />
                        <br /> 
                        <Row >
                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Harga Jual</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan harga jual normal.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 20000" value={this.state.harga_jual_single} onChange={(text)=>this.onChangeTextArea(text.target.value,"harga_jual_single",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>
                        </Row>

                        <br />
                        <br /> 

                        <Row >
                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Harga Jual Reseller</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan harga jual reseller.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 20000" value={this.state.harga_jual_reseller_single} onChange={(text)=>this.onChangeTextArea(text.target.value,"harga_jual_reseller_single",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>
                        </Row>

                        <br />
                        <br /> 

                        <Row >
                          <Col>
                            <Row className="d-flex" >
                              <div className="d-flex justify-content-center" style={{padding:2, height:20 }}>
                                <h6 className="m-0" style={{fontWeight:'bold',alignSelf:'center'}}>Minimal Order</h6>
                              </div>
                              <div className="d-flex justify-content-center" style={{padding:2,backgroundColor:'#F3F4F5', color:'#9FA6B0', borderRadius:8, marginLeft:2, width:55, height:20 }}>
                                <small style={{alignSelf:'center',fontWeight:'bold'}}>Wajib</small>
                              </div>
                            </Row>
                            <Row className="w-50 mt-2" size="sm">
                                <small>
                                  Cantumkan minimal order.
                                </small>
                            </Row>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex" style={{height:20}}>
                              <FormInput placeholder="Contoh: 20000" value={this.state.min_order} onChange={(text)=>this.onChangeTextArea(text.target.value,"min_order",0,0)} />
                              <small>0/70</small>
                            </Row>
                          </Col>
                        </Row>                        
                        
                        
                        <br />
                        <br /> 
                        <Row >
                          <Col>
                          </Col>

                          <Col md="8" >
                            <Row className="d-flex flex-row-reverse">
                              <Button theme="primary" onClick={()=>this.addProduct()}>
                                <span className="text-success">
                                  <i className="material-icons">check</i>
                                </span>{" "}
                                Add Product
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    }


                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {variant_test.length >0 ?
          <Card large style={{height:'auto'}}>

            <CardBody style={{height:'auto'}}>
              <CardGroup>
                <table className="table">
                  <thead className="bg-light">
                    <tr>
                      {variant_test.map((variant_test)=>(
                        <th scope="col" className="border-0">
                          {variant_test}
                        </th>
                      ))}
                      <th scope="col" className="border-0">
                        SKU
                      </th>
                      <th scope="col" className="border-0">
                        Stok
                      </th>
                      <th scope="col" className="border-0">
                        Harga Modal
                      </th>
                      <th scope="col" className="border-0">
                        Harga Jual
                      </th>
                      <th scope="col" className="border-0">
                        Harga Jual Reseller
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                  

                      {variant_sub.length >1 ? 
                        variant_sub[0].variant_value.map((value,i)=>(
                          
                          <tr rowSpan={4}>
                            <th >{value}</th>

                            <td >
                            {variant_sub[1].variant_value.length > 1 ?
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr style={{height:60}}>
                                  <td>{values}</td>
                                </tr>
                              ))
                                :
                                <></>
                              }
                            </td>

                            <td >
                            {this.state.variant_sub_sku.length > 1 ?
                              
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr>
                                  <td><FormInput placeholder={'Sku'} value={this.state.variant_sub_sku[i][j] != ''?this.state.variant_sub_sku[i][j]:''} onChange={(text)=>this.onChangeTextArea(text.target.value,"sku_array",i,j)} /></td>
                                </tr>
                              ))


                                :
                                <></>
                              }
                            </td>

                            <td >
                            {this.state.variant_sub_stock.length > 1 ?
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr>
                                  <td><FormInput placeholder="Stok" value={this.state.variant_sub_stock[i][j]} onChange={(text)=>this.onChangeStocks(text.target.value,"stock_array",i,j)} /></td>
                                </tr>
                              ))


                                :
                                <></>
                              }
                            </td>

                            <td >
                            {this.state.variant_sub_capital_price.length > 1 ?
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr>
                                  <td><FormInput placeholder="Harga Beli" value={this.state.variant_sub_capital_price[i][j] != ''?this.state.variant_sub_capital_price[i][j]:''} onChange={(text)=>this.onChangeTextArea(text.target.value,"capital_price_array",i,j)} /></td>
                                </tr>
                              ))


                                :
                                <></>
                              }
                            </td>

                            <td >
                            {this.state.variant_sub_price.length > 1 ?
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr>
                                  <td><FormInput placeholder="Harga Jual" value={this.state.variant_sub_price[i][j] != ''?this.state.variant_sub_price[i][j]:''} onChange={(text)=>this.onChangeTextArea(text.target.value,"price_array",i,j)} /></td>
                                </tr>
                              ))


                                :
                                <></>
                              }
                            </td>

                            <td >
                            {this.state.variant_sub_price_reseller.length > 1 ?
                              variant_sub[1].variant_value.map((values,j)=>(
                                <tr>
                                  <td><FormInput placeholder="Harga Jual Reseller" value={this.state.variant_sub_price_reseller[i][j] != ''?this.state.variant_sub_price_reseller[i][j]:''} onChange={(text)=>this.onChangeTextArea(text.target.value,"price_reseller_array",i,j)} /></td>
                                </tr>
                              ))


                                :
                                <></>
                              }
                            </td> 


                          </tr>
                          
                        ))
                          :
                        <></>
                      }
                  
                  
                  
                  </tbody>
                </table>
              </CardGroup>
            </CardBody>
              <CardFooter>
                <Button theme="primary" onClick={()=>this.addProductVariant()}>
                  <span className="text-success">
                    <i className="material-icons">check</i>
                  </span>{" "}
                  Add Product
                </Button>
              </CardFooter>
          </Card>
          :
          <></>
         }
        <Snackbar
            open={this.state.addProductSnackBar}
            autoHideDuration={6000}
            onClose={()=>this.closeSnackBar('addProductSnackBar')}
            message={this.state.messageApi}

          />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  name: state.user.name,
  addProductResponse: state.product.addProductResponse,
  readProductResponse: state.product.readProductResponse,
  withRouter : state.withRouter
});
const mapDispatchToProps = {
  readAllProduct,
  addProduct
};


export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
