import React from "react";
import ReactTable from "react-table";
import FuzzySearch from "fuzzy-search";
import dateFormat from "dateformat";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  FormCheckbox,

} from "shards-react";
import Tooltip from '@material-ui/core/Tooltip';

import PageTitle from "../components/common/PageTitle";
import { connect } from "react-redux";
import { readAllProduct } from "../redux/actions/ProductsAction";
import { updateKeyword, deleteKeyword } from "../redux/actions/KeywordAction";
import ModalYesOrNo from '../components/modal/modalYesOrNo';
import ModalYesOrNoOneString from '../components/modal/modalYesOrNoOneString';
import { numberThousandSeparator } from '../utils/general';
import { Redirect,NavLink } from "react-router-dom";

class ListProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:null,
      newKeyword:null,
      showModalYesOrNo: false,
      showModalOneString: false,
      isLogin:true,
      titleModalYesOrNo:null, 
      subtitleModalYesOrNo:null, 
      placeholderModalYesOrNo:null, 
      buttonPositiveModalYesOrNo:null,      
      dataModal:null,
      titleModalOneString:null, 
      subtitleModalOneString:null, 
      placeholderModalOneStringA:null, 
      position:null,
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      tableData: []
    }
    this.canvasRef = React.createRef();
  }
  refreshData = ()=>{
    const { token, readAllProduct } = this.props
    readAllProduct(token);
  }
  componentDidMount = () => {
    const { token, readAllProduct } = this.props
    readAllProduct(token);
  }
  componentDidUpdate = (prevProps) => {
    const { readProductResponse } = this.props

    if (prevProps.readProductResponse !== this.props.readProductResponse) {
        if(readProductResponse.code!=401){
          this.setState({
            data:readProductResponse.data,
          })
        }else{
          this.setState({isLogin:false})  
        }

    }
   
  }

  showModaYesOrNoController =(titleModalYesOrNo, subtitleModalYesOrNo, buttonPositiveModalYesOrNo, data, position)=>{
    this.setState({
      titleModalYesOrNo:titleModalYesOrNo,
      subtitleModalYesOrNo:subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo:buttonPositiveModalYesOrNo,
      position:position,
      dataModal:data,
      showModalYesOrNo: true
    })
  
  }
  positiveModalYesOrNo =(position, data)=>{
    const { token, deleteKeyword } = this.props
    console.log('positiveModalYesOrNo DATA', data)
    console.log('positiveModalYesOrNo POSITION', position)
    if(position="DELETE_KEYWORD"){
      let params = {
        id:data.id
      }
      deleteKeyword(token, params)
    }
    this.setState({
      showModalYesOrNo: false
    })
  }
  negativeModalYesOrNo =()=>{
    this.setState({
      showModalYesOrNo: false
    })
  }

  showModaOneStringlController =(titleModalOneString, subtitleModalOneString, placeholderModalOneStringA, data, position)=>{
    this.setState({
      titleModalOneString:titleModalOneString,
      subtitleModalOneString:subtitleModalOneString,
      placeholderModalOneStringA:placeholderModalOneStringA,
      dataModal:data,
      position:position,
      showModalOneString: true
    })

  }
  positiveModalOneString =(position, data)=>{
    const { token, updateKeyword } = this.props
    if(position="UPDATE_KEYWORD"){
      let params = {
        id:data.data.id,
        intent_id: data.data.id,
        sub_intent_name:data.value
      }
      updateKeyword(token, params)
    }
    this.setState({
      showModalOneString: false
    })
  }
  negativeModalOneString =()=>{
    this.setState({
      showModalOneString: false
    })
  }
  handleChangeCheckBox = (e)=>{
    console.log("handleChangeCheckBox",e)
  }
  handlePageSizeChange= (e)=>{
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }
  render(){
    const { data, newKeyword,
      showModalYesOrNo,
      titleModalYesOrNo, 
      subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo,
      dataModal,
      showModalOneString, 
      titleModalOneString, 
      subtitleModalOneString, 
      placeholderModalOneStringA,
      position} = this.state

    if(this.state.isLogin===true){
      return(
        <Container fluid className="main-content-container px-4">
        {/* Page Header */}
          <Row noGutters className="page-header py-4">
              <PageTitle sm="4" subtitle="Daftar Produk" className="text-sm-right mb-2"/>
          </Row>
        {data != null ? 
          <>
        <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row className="d-flex justify-content-between">


                  <Col className="d-flex flex-row " md="3">
                    <InputGroup seamless size="ml" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput placeholder="Cari nama produk atau SKU" onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>

                  <Col className="d-flex align-items-center w-100" size="lg" md="4">
                    <span className="mr-2">Show </span>
                    <FormSelect
                      size="sm"
                      className="mr-2"
                      value={this.state.pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {this.state.pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size} rows
                        </option>
                      ))}
                    </FormSelect>
                    <Button tag={NavLink} to="/add-product" theme="primary" size="ml" className="mr-1 w-50">
                      + Tambah Produk
                    </Button>
                  </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      NAME
                    </th>
                    <th scope="col" className="border-0">
                      CASHIER
                    </th>
                    <th scope="col" className="border-0">
                      HARGA
                    </th>
                    <th scope="col" className="border-0">
                      STOK
                    </th>
                    <th scope="col" className="border-0">
                      AKTIF
                    </th>
                  </tr>
                </thead>
                <tbody>
                {data.map((data_product)=>(
                  <tr>
                    <td>{data_product.name}</td>
                    <td>{data_product.cashier_name}</td>
                    <td>{numberThousandSeparator(data_product.min_price,true)}-{numberThousandSeparator(data_product.max_price,true)}</td>
                    <td>{data_product.stock}</td>
                    <td><FormCheckbox toggle small checked={data_product.active == 1 ? true:false} onChange={e => this.handleChangeCheckBox(data_product.oid+'-'+data_product.cid+'-'+data_product.pid)} /></td>
                    <td>107-0339</td>
                  </tr>
                  ))}

                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
        </Row>
          </>
        
          :
          <Row noGutters className="page-header py-4">
            <h6>Tidak ada produk</h6>
          </Row>
          
          }
          <ModalYesOrNo 
            titleModalYesOrNo={titleModalYesOrNo}
            subtitleModalYesOrNo={subtitleModalYesOrNo}
            showModal={showModalYesOrNo} 
            position={position}
            data={dataModal}
            positiveModalYesOrNo={this.positiveModalYesOrNo}
            negativeModalYesOrNo={this.negativeModalYesOrNo} 
            buttonPositiveModalYesOrNo={buttonPositiveModalYesOrNo}/>
          <ModalYesOrNoOneString 
            titleModalOneString={titleModalOneString}
            subtitleModalOneString={subtitleModalOneString}
            placeholderModalOneStringA={placeholderModalOneStringA}
            showModal={showModalOneString} 
            position={position}
            data={dataModal}
            positiveModalOneString={this.positiveModalOneString}
            negativeModalOneString={this.negativeModalOneString} />    

      
      </Container>
      )
      }else{
        return <Redirect to={'/signin'} />
      }
  }
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  readProductResponse: state.product.readProductResponse,

  addKeywordResponse:state.keyword.addKeywordResponse,
  updateKeywordResponse:state.keyword.updateKeywordResponse,
  deleteKeywordResponse:state.keyword.deleteKeywordResponse,
  readKeywordResponse:state.keyword.readKeywordResponse,
  withRouter : state.withRouter
});
const mapDispatchToProps = {
  readAllProduct,
  updateKeyword,
  deleteKeyword
};


export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
