import React from "react";
import { Container, Row, Col, Button } from "shards-react";

import PageTitleAdd from "../components/CategoriesComponents/PageTitleAdd";
import SmallStats from "./../components/CategoriesComponents/SmallStats";
import { Redirect  } from "react-router-dom";
import SidebarCategories from "../components/CategoriesComponents/SidebarCategories";

import { connect } from "react-redux";
import { readAllProduct } from "../redux/actions/ProductsAction";
import Snackbar from '@material-ui/core/Snackbar';
import ModalYesOrNo from '../components/modal/modalYesOrNo';

class BlogOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal:false,
      open: false,
      vertical: 'bottom',
      horizontal: 'center',
      messages:null,
      data: 
      [
        {
          id: 1,
          category_intent_name: "All Market",
          data_intents:[
                  {
                    label: "Pesanan Baru",
                    value: "2,390",
                    percentage: "4.7%",
                    increase: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: [1, 2, 1, 3, 5, 4, 7]
                    }
                    ]
                  },
                  {
                    label: "Siap dikirim",
                    value: "182",
                    percentage: "12.4",
                    increase: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderColor: "rgb(23,198,113)",
                      data: [1, 2, 3, 3, 3, 4, 4]
                    }
                    ]
                  },
                  {
                    label: "Dalam pengiriman",
                    value: "8,147",
                    percentage: "3.8%",
                    increase: false,
                    decrease: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "4", sm: "6" },
                    datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [2, 3, 3, 3, 4, 3, 3]
                    }
                    ]
                  },
                  {
                    label: "Dibatalkan",
                    value: "8,147",
                    percentage: "3.8%",
                    increase: false,
                    decrease: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "4", sm: "6" },
                    datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [2, 3, 3, 3, 4, 3, 3]
                    }
                    ]
                  },
                  {
                    label: "Pesanan dikomplain",
                    value: "8,147",
                    percentage: "3.8%",
                    increase: false,
                    decrease: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "4", sm: "6" },
                    datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [2, 3, 3, 3, 4, 3, 3]
                    }
                    ]
                  }
                ]
        },
        {
          id: 2,
          category_intent_name: "Tokopedia",
          data_intents:[
            {
              label: "Pesanan Baru",
              value: "2,390",
              percentage: "4.7%",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [1, 2, 1, 3, 5, 4, 7]
              }
              ]
            },
            {
              label: "Siap dikirim",
              value: "182",
              percentage: "12.4",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(23,198,113,0.1)",
                borderColor: "rgb(23,198,113)",
                data: [1, 2, 3, 3, 3, 4, 4]
              }
              ]
            },
            {
              label: "Dalam pengiriman",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Dibatalkan",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Pesanan dikomplain",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            }
          ]
        },
        {
          id: 3,
          category_intent_name: "Shopee",
          data_intents:[
            {
              label: "Pesanan Baru",
              value: "2,390",
              percentage: "4.7%",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [1, 2, 1, 3, 5, 4, 7]
              }
              ]
            },
            {
              label: "Siap dikirim",
              value: "182",
              percentage: "12.4",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(23,198,113,0.1)",
                borderColor: "rgb(23,198,113)",
                data: [1, 2, 3, 3, 3, 4, 4]
              }
              ]
            },
            {
              label: "Dalam pengiriman",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Dibatalkan",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Pesanan dikomplain",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            }
          ]
        },
        {
          id: 4,
          category_intent_name: "Lazada",
          data_intents:[
            {
              label: "Pesanan Baru",
              value: "2,390",
              percentage: "4.7%",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [1, 2, 1, 3, 5, 4, 7]
              }
              ]
            },
            {
              label: "Siap dikirim",
              value: "182",
              percentage: "12.4",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(23,198,113,0.1)",
                borderColor: "rgb(23,198,113)",
                data: [1, 2, 3, 3, 3, 4, 4]
              }
              ]
            },
            {
              label: "Dalam pengiriman",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Dibatalkan",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            },
            {
              label: "Pesanan dikomplain",
              value: "8,147",
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: [2, 3, 3, 3, 4, 3, 3]
              }
              ]
            }
          ]
        }
      ],
      redirect:false
    }
  }
  componentDidMount = () => {
    const { token, readAllProduct } = this.props
    readAllProduct(token);
  }
  refreshData = ()=>{
    const { token, readAllProduct } = this.props
    readAllProduct(token);
  }
  componentDidUpdate = (prevProps) => {
    const { readCategoryResponse, addCategoryResponse, deleteCategoryResponse, updateCategoryResponse } = this.props
    
    if (prevProps.readCategoryResponse !== this.props.readCategoryResponse) {
        this.setState({
            data:readCategoryResponse.data,
            open: false,
            messages: null
        })
    }

    if (prevProps.addCategoryResponse !== this.props.addCategoryResponse) {
      this.setState({
        open: true,
        messages:addCategoryResponse.message,
      })
      setTimeout(()=>{
        this.refreshData()
      },1800)
      
    }
    if (prevProps.deleteCategoryResponse !== this.props.deleteCategoryResponse) {
      this.refreshData()
    }
    if (prevProps.updateCategoryResponse !== this.props.updateCategoryResponse) {
      this.refreshData()
    }

    if (prevProps.addIntentResponse !== this.props.addIntentResponse) {
      this.refreshData()
    }
    if (prevProps.deleteIntentResponse !== this.props.deleteIntentResponse) {
      this.refreshData()
    }
    if (prevProps.updateIntentResponse !== this.props.updateIntentResponse) {
      this.refreshData()
    }

    if (prevProps.addKeywordResponse !== this.props.addKeywordResponse) {
      this.refreshData()
    }
    if (prevProps.updateKeywordResponse !== this.props.updateKeywordResponse) {
      this.refreshData()
    }
    if (prevProps.deleteKeywordResponse !== this.props.deleteKeywordResponse) {
      this.refreshData()
    }
}
  render() {
    const { 
      open,
      vertical,
      horizontal,
      messages,
      redirect, 
      showModal, 
      data } = this.state
    if(this.props.token!=null){
    return (
      <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      <Row noGutters className="d-flex justify-content-center py-2">
        <Col lg="5" md="12">
          <SidebarCategories />
        </Col>
      </Row>

      {data != null ? data.map((data_category)=>(
        <>
            <Row noGutters className="page-header py-2">
              <PageTitleAdd subtitle="CATEGORY" title={data_category.category_intent_name} id={data_category} className="text-sm-right mb-2" />
            </Row>
            <div class="container-fluid">
                  <Row className="d-flex flex-row flex-nowrap overflow-auto">
                   
                    {data_category.data_intents.map((stats, idx) => (
                      
                        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                          <SmallStats
                            id={`small-stats-${idx}`}
                            variation="1"
                            chartData={stats.datasets}
                            chartLabels={stats.chartLabels}
                            label={stats.label}
                            value={stats.data_keywords_size}
                            wording={stats.intent_wording_pertama}
                            data={stats}
                            percentage={stats.percentage}
                            increase={stats.increase}
                            decrease={stats.decrease}
                          />
                        </Col>
                      ))}
                  </Row>
            </div>
          </>
      ))
      :
      <h6>Tidak ada product</h6>
    
    }

    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={messages}
        key={vertical + horizontal}
      />
    </Container>
    );
  }else{
    return <Redirect to={'/signin'} />
  }
  }
}
  
    




const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  readProductResponse: state.product.readProductResponse,
  addProductResponse: state.product.addProductResponse,
  updateProductResponse: state.product.updateProductResponse,
  deleteProductResponse: state.product.deleteProductResponse,

  readIntentResponse: state.intent.readIntentResponse,
  addIntentResponse: state.intent.addIntentResponse,
  updateIntentResponse: state.intent.updateIntentResponse,
  deleteIntentResponse: state.intent.deleteIntentResponse,

  addKeywordResponse:state.keyword.addKeywordResponse,
  updateKeywordResponse:state.keyword.updateKeywordResponse,
  deleteKeywordResponse:state.keyword.deleteKeywordResponse,
  readKeywordResponse:state.keyword.readKeywordResponse,

  withRouter : state.withRouter
});
const mapDispatchToProps = {
  readAllProduct,
  
};
export default connect(mapStateToProps, mapDispatchToProps)(BlogOverview);
