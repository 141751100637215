import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { 
  Col, 
  Row, 
  Button,
 } from "shards-react";
import Tooltip from '@material-ui/core/Tooltip';
import ModalYesOrNo from '../modal/modalYesOrNo';
import ModalYesOrNoOneString from '../modal/modalYesOrNoOneString';
import ModalYesOrNoTwoString from '../modal/modalYesOrNoTwoString';
import { connect } from "react-redux";
import { updateProduct, deleteProduct } from "../../redux/actions/ProductsAction";
import { addIntent } from "../../redux/actions/IntentAction";

class PageTitleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title:props.title, 
      subtitle:props.subtitle, 
      className:props.className, 
      showModalOneString: false,
      showModalTwoString: false,


      showModalYesOrNo: false,
      titleModalYesOrNo:null, 
      subtitleModalYesOrNo:null, 
      placeholderModalYesOrNo:null, 
      buttonPositiveModalYesOrNo:null,      

      titleModalOneString:null, 
      subtitleModalOneString:null, 
      placeholderModalOneStringA:null, 

      titleModalTwoString:null, 
      subtitleModalTwoString:null, 
      placeholderModalTwoStringA:null, 
      placeholderModalTwoStringB:null,
      data:null,
      position:null

    }
    this.canvasRef = React.createRef();
  }
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.object
  }

  static defaultProps = {
    title: '',
    subtitle: '',
    className:''
  }
  componentDidMount() {

  }

  showModaYesOrNoController =(titleModalYesOrNo, subtitleModalYesOrNo, buttonPositiveModalYesOrNo, position, data)=>{
    this.setState({
      titleModalYesOrNo:titleModalYesOrNo,
      subtitleModalYesOrNo:subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo:buttonPositiveModalYesOrNo,
      position:position,
      data:data,
      showModalYesOrNo: true
    })
  
  }
  positiveModalYesOrNo =(position, data)=>{
    const { token, deleteProduct } = this.props
    if(position="DELETE_CATEGORY"){
      let params = {
        id:data.id
      }
      deleteProduct(token, params)
    }
    this.setState({
      showModalYesOrNo: false
    })
  }
  negativeModalYesOrNo =()=>{
    this.setState({
      showModalYesOrNo: false
    })
  }

  showModaOneStringlController =(titleModalOneString, subtitleModalOneString, placeholderModalOneStringA, data, position)=>{
    this.setState({
      titleModalOneString:titleModalOneString,
      subtitleModalOneString:subtitleModalOneString,
      placeholderModalOneStringA:placeholderModalOneStringA,
      data:data,
      position:position,
      showModalOneString: true
    })

  }
  positiveModalOneString =(position, data)=>{
    const { token, updateProduct } = this.props
    if(position="UPDATE_CATEGORY"){
      let params = {
        id:data.data.id,
        category_intent_name:data.value
      }
      updateProduct(token, params)
    }
    this.setState({
      showModalOneString: false
    })
  }
  negativeModalOneString =()=>{
    this.setState({
      showModalOneString: false
    })
  }

  showModaTwoStringlController =(titleModalTwoString, subtitleModalTwoString, placeholderModalTwoStringA, placeholderModalTwoStringB, data, position)=>{
    this.setState({
      titleModalTwoString:titleModalTwoString,
      subtitleModalTwoString:subtitleModalTwoString,
      placeholderModalTwoStringA:placeholderModalTwoStringA,
      placeholderModalTwoStringB:placeholderModalTwoStringB,
      data:data,
      position:position,
      showModalTwoString: true
    })

  }
  positiveModalTwoString =(position, data)=>{
    const { token, addIntent } = this.props
    if(position="ADD_INTENT"){
      let params = {
        category_intent_id:data.data.id,
        intent_name:data.ModalTwoStringA,
        intent_wording_pertama: data.ModalTwoStringB
      }
      console.log('addIntent DATA', data)
      console.log('addIntent PARAMS', params)
      addIntent(token, params)
    }
    this.setState({
      showModalTwoString: false
    })
  }
  negativeModalTwoString =()=>{
    this.setState({
      showModalTwoString: false
    })
  }
  render() {
    const { className, title, subtitle, id} =this.props
    const { 
      showModalYesOrNo,
      titleModalYesOrNo, 
      subtitleModalYesOrNo,
      buttonPositiveModalYesOrNo,

      showModalOneString, 
      titleModalOneString, 
      subtitleModalOneString, 
      placeholderModalOneStringA, 

      showModalTwoString,
      titleModalTwoString, 
      subtitleModalTwoString, 
      placeholderModalTwoStringA, 
      placeholderModalTwoStringB,
      data,
      position
    } = this.state

    const classes = classNames(
      className,
      "text-center",
      "text-md-left",
      "mb-sm-0",
    );
    
    return (
      <Row xs="12" sm="4" className={classes} >
        <Col>
          <span className="text-uppercase page-subtitle">{subtitle}</span>
          <Row className="pl-3">
            <h3 className="page-title">{title}</h3>
            <Tooltip title="Add Intent">
              <Button theme="grey" 
                onClick={()=> this.showModaTwoStringlController("Add new intent for "+title,"Please fill Intent Name and Wording", "Intent Name", "Wording",id, "ADD_INTENT")}
                style={{borderColor:'#d3d3d3'}} 
                className="pt-auto px-2 ml-2" >
                <i className="material-icons">add</i>
              </Button>
            </Tooltip>
            <Tooltip title="Edit Category">
              <Button theme="grey"
                onClick={()=> this.showModaOneStringlController("Edit category name for "+title,"Please fill new category name", title, id, "UPDATE_CATEGORY")}
                style={{borderColor:'#d3d3d3'}} className="pt-auto px-2 ml-2" data-toggle="modal" data-target="#editCategory">
                <i className="material-icons">edit</i>
              </Button>
            </Tooltip>
            <Tooltip title="Details Category">
              <Button theme="grey" style={{borderColor:'#d3d3d3'}} className="pt-auto px-2 ml-2" data-toggle="modal" data-target="#editCategory">
                <i className="material-icons">visibility</i>
              </Button>
            </Tooltip>
            <Tooltip title="Delete Category">
              <Button theme="grey"
              onClick={()=> this.showModaYesOrNoController("Delete Category","Warning: If you are sure to delete this category ("+title+"), you will to delete all intents in this category ("+title+")","Delete","DELETE_CATEGORY", id)} style={{borderColor:'#d3d3d3'}} className="pt-auto px-2 ml-2" data-toggle="modal" data-target="#editCategory">
                <i className="material-icons">delete</i>
              </Button>
            </Tooltip>

          </Row>
          
        </Col>
        <ModalYesOrNoOneString 
          titleModalOneString={titleModalOneString}
          subtitleModalOneString={subtitleModalOneString}
          placeholderModalOneStringA={placeholderModalOneStringA}
          showModal={showModalOneString} 
          position={position}
          data={id}
          positiveModalOneString={this.positiveModalOneString}
          negativeModalOneString={this.negativeModalOneString} />
        <ModalYesOrNoTwoString 
          titleModalTwoString={titleModalTwoString}
          subtitleModalTwoString={subtitleModalTwoString}
          placeholderModalTwoStringA={placeholderModalTwoStringA}
          placeholderModalTwoStringB={placeholderModalTwoStringB}
          showModal={showModalTwoString} 
          position={position}
          data={id}
          positiveModalTwoString={this.positiveModalTwoString}
          negativeModalTwoString={this.negativeModalTwoString} />
        <ModalYesOrNo 
          titleModalYesOrNo={titleModalYesOrNo}
          subtitleModalYesOrNo={subtitleModalYesOrNo}
          showModal={showModalYesOrNo} 
          position={position}
          data={id}
          positiveModalYesOrNo={this.positiveModalYesOrNo}
          negativeModalYesOrNo={this.negativeModalYesOrNo}
          buttonPositiveModalYesOrNo={buttonPositiveModalYesOrNo} />
      </Row>
    );
  }
};
const mapStateToProps = state => ({
  loading: state.user.loading,
  token: state.user.token,
  withRouter : state.withRouter
});

const mapDispatchToProps = {
  addIntent,
  updateProduct,
  deleteProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTitleAdd)

