import { combineReducers } from 'redux';

import sidebarReducer from "./sidebar";
import userReducer from "./User";
import productReducer from "./Product";
import intentReducer from "./Intent";
import keywordReducer from "./Keyword";
export default combineReducers({
    sidebar: sidebarReducer,
    user: userReducer,
    product: productReducer,
    intent: intentReducer,
    keyword: keywordReducer
});