import { 
    SIGNIN_REQUEST,
    SET_USER_DATA,
    UNSET_USER_DATA} from './types';
    
import ApiService from '../../services/ApiServices';

export const siginRequest  = (data) => async (dispatch) => {
    try {
      const res = await ApiService.signin(data);
      console.log(res.data)
      dispatch({
        type: SIGNIN_REQUEST,
        data: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    data: data
});

export const unsetUserData  = (token,data) => async (dispatch) => {
  try {
    const res = await ApiService.logout(token,data);
   
    dispatch({
      type: UNSET_USER_DATA,
      data: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};